"use client"
import React from "react";
import { Navbar as NextUiNavbar, NavbarContent, NavbarItem } from "@nextui-org/navbar";
import Link from "next/link";
import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from "@nextui-org/dropdown";
import { Button } from "@nextui-org/button";
import { FaChevronDown } from "react-icons/fa6";

export default function Navbar({types,categories,countries}) {
  return (
    <NextUiNavbar isBordered shouldHideOnScroll maxWidth="2xl" className="bg-default hidden md:flex" >
      <NavbarContent className="w-full hidden sm:flex gap-12" justify="center">
          {
            categories.map((category) => 
              <NavbarItem key={category.id}>
                <Link prefetch={false} color="foreground" href={`/danh-muc/${category.slug}`}>
                  {category.name}
                </Link>
              </NavbarItem>
            )
          }
          {/* <Dropdown>
            <NavbarItem>
              <DropdownTrigger>
                <Button
                  disableRipple
                  className="p-0 bg-transparent data-[hover=true]:bg-transparent"
                  endContent={<FaChevronDown/>}
                  radius="sm"
                  variant="light"
                >
                  Thể Loại
                </Button>
              </DropdownTrigger>
            </NavbarItem>
            <DropdownMenu
              aria-label="ACME features"
              className="w-[600px]"
              classNames={{
                list:"grid grid-cols-3"
              }}
              itemClasses={{
                base: "gap-4",
              }}
            >
              {
                types.map((item) => 
                  <DropdownItem key={item.id}>
                    <Link className="block" prefetch={false} href={`/the-loai/${item.slug}`}>
                      {item.name}
                    </Link>
                  </DropdownItem>
                )
              }
            </DropdownMenu>
          </Dropdown> */}
          {/* <Dropdown>
            <NavbarItem>
              <DropdownTrigger>
                <Button
                  disableRipple
                  className="p-0 bg-transparent data-[hover=true]:bg-transparent"
                  endContent={<FaChevronDown/>}
                  radius="sm"
                  variant="light"
                >
                  Quốc Gia
                </Button>
              </DropdownTrigger>
            </NavbarItem>
            <DropdownMenu
              aria-label="Quốc Gia"
              className="w-[500px]"
              classNames={{
                list:"grid grid-cols-3"
              }}
              itemClasses={{
                base: "gap-4",
              }}
            >
                {
                  countries.map((item) => 
                    <DropdownItem key={item.id}>
                      <Link className="block" prefetch={false} href={`/quoc-gia/${item.slug}`}>
                        Phim {item.name}
                      </Link>
                    </DropdownItem>
                  )
                }
            </DropdownMenu>
          </Dropdown> */}
      </NavbarContent>
    </NextUiNavbar>
  );
}

export const currentDateTimeToString = (date) => {
    var mm = date.getMonth() + 1;
    var dd = date.getDate();
    var h = date.getHours();
    var m = date.getMinutes();
    var s = date.getSeconds();
    return date.getFullYear() + '-' + (mm > 9 ? '' : '0') + mm + '-' + (dd > 9 ? '' : '0') + dd + ' ' + (h > 9 ? '' : '0') + h + ':' + (m > 9 ? '' : '0') + m + ':' + (s > 9 ? '' : '0') + s;
}
export const findNonMatchingItems = (arr1, arr2,keysToCompare) => {
    const nonMatchingItems = [];
    arr1.forEach((item1) => {
      const matchingItem = arr2.find((item2) =>
        keysToCompare.every((key) => key === "qty" ? parseFloat(item1[key]) === parseFloat(item2[key]) : item1[key] === item2[key])
      );
      if (!matchingItem) {
        nonMatchingItems.push(item1);
      }
    });
    return nonMatchingItems.length > 0 ? nonMatchingItems : null;
}

export const containsAnyKeyword = (text, keywords) => {
  return keywords.some(keyword => text.toLowerCase().includes(keyword.toLowerCase()));
}

export const isBase64 = (str) => {
  const base64Regex = /^(?:[A-Za-z0-9+\/]{4})*(?:[A-Za-z0-9+\/]{2}(?:[A-Za-z0-9+\/]|=){0,2})?$/;
  return base64Regex.test(str);
}

export const vietnameseToSlug = (str) => {
  // Chuyển chuỗi sang dạng slug
  str = str.toLowerCase().trim();

  // Tạo một bảng chữ cái tương ứng với dấu tiếng Việt
  const from = "àáạảãâầấậẩẫăằắặẳẵèéẹẻẽêềếệểễìíịỉĩòóọỏõôồốộổỗơờớợởỡùúụủũưừứựửữỳýỵỷỹđ";
  const to = "aaaaaaaaaaaaaaaaaeeeeeeeeeeeiiiiiooooooooooooooooouuuuuuuuuuuyyyyyd";

  // Loại bỏ dấu tiếng Việt
  for (let i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  // Loại bỏ các ký tự không phải chữ cái, số, hoặc dấu gạch ngang
  str = str.replace(/[^a-z0-9\-]/g, '-');

  // Loại bỏ các dấu gạch ngang liên tiếp
  str = str.replace(/-+/g, '-');

  // Loại bỏ dấu gạch ngang ở đầu và cuối chuỗi
  str = str.replace(/^-+|-+$/g, '');

  return str;
}
export const extractVideoId = (url) => {
  const regex = /[?&]v=([^&]+)/;
  const match = url.match(regex);
  if (match) {
    return `https://www.youtube.com/embed/${match[1]}`;
  } else {
    return null;
  }
}
export const isYoutubeUrl = (url) => {
  const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\//;
  return youtubeRegex.test(url);
}
export const checkFileExtension = (url,comp) => {
  // Tách phần mở rộng từ URL bằng cách tìm dấu chấm cuối cùng
  const dotIndex = url.lastIndexOf('.');

  if (dotIndex === -1) {
      // Nếu không tìm thấy dấu chấm, trả về false
      return false;
  }

  // Lấy phần mở rộng của tệp
  let extension = url.substring(dotIndex + 1).toLowerCase();
  
  const questionMarkIndex = extension.indexOf('?');
  // Nếu có dấu ?, cắt chuỗi tại vị trí đó
  if (questionMarkIndex !== -1) {
      extension = extension.substring(0, questionMarkIndex);
  }

  // So sánh phần mở rộng với comp
  return extension.trim() === comp.trim();
}
export const checkFileType = (url) => {
  var mp4Regex = /\.mp4($|\?)/i;
  var m3u8Regex = /\.m3u8($|\?)/i;

  if (mp4Regex.test(url)) {
      return 'MP4';
  } else if (m3u8Regex.test(url)) {
      return 'M3U8';
  } else {
      return 'Unknown';
  }
}
export const checkIsGreaterThanOneDay = (dateTime) => {
  const currentDate = new Date();
  const givenDate = new Date(dateTime);
  const isDifferentDay = currentDate.getDate() !== givenDate.getDate() ||
                        currentDate.getMonth() !== givenDate.getMonth() ||
                        currentDate.getFullYear() !== givenDate.getFullYear();

  if (isDifferentDay) {
    return true
  } else {
    return false
  }
}

export const isValidJSONObject = (str) => {
  try {
      const parsed = JSON.parse(str);
      return parsed !== null && typeof parsed === 'object' && !Array.isArray(parsed);
  } catch (e) {
      return false;
  }
}

export const isShortInkUrl = (urlString) => {
  try {
      const url = new URL(urlString);
      return url.hostname === 'short.ink' && url.protocol === 'https:';
  } catch (error) {
      return false;
  }
}

export const numberFormat = (str) => {
  return new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(str);
}